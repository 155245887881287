import React from "react";

import { ColumnsType } from "antd/es/table";

import {
  MinusSVG,
  TickOutlineSVG,
  Tier1BadgeSVG,
  Tier2BadgeSVG,
  Tier3BadgeSVG,
  Tier4BadgeSVG,
  Tier4BadgeUkSVG,
} from "assets/icons";
import {
  Tier1ShortBadgeSVG,
  Tier2ShortBadgeSVG,
  Tier3ShortBadgeSVG,
  Tier4ShortBadgeSVG,
  Tier4ShortBadgeUkSVG,
} from "components/TierBadge/icons";

import styles from "./ComparisonTiersTable.module.scss";

type RowData = {
  isTitle?: boolean;
  key: string;
  features: string;
  tier_1: boolean | "soon";
  tier_2: boolean | "soon";
  tier_3: boolean | "soon";
  tier_4: boolean | "soon";
};

export const getTableData = (isUkFinProm?: boolean): RowData[] => [
  {
    key: "freeBFWealthbuilderProgram",
    features: isUkFinProm ? "BF Wealth Builder Program" : "Free BF Wealth Builder Program",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "buyAndSellVirtualAssets",
    features: "Buy & Sell Virtual Assets (BTC, ETH, USDT etc.)",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "stakeEthForWeeklyIncome",
    features: "Stake Ethereum for weekly income",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "autoRestaking",
    features: "Auto Restaking for weekly compounded income",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "autoInvest",
    features: "Auto Invest & Auto Trade features",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "bankDeposits",
    features: "Bank Deposits in Your local Fiat Currency",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "cryptoDeposits",
    features: "Crypto Deposits (BTC, ETH, USDT etc.)",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "bankWithdrawals",
    features: "Bank Withdrawals",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "virutalAssetsWithdrawals",
    features: "Virtual Asset Withdrawals (BTC, ETH, USDT etc.)",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "applyForfinance",
    features: "Apply for finance as a FinTech company",
    tier_1: true,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "viewPortfolioFundAndTransactionHis",
    features: "View Portfolio, Funds and Transaction History",
    tier_1: false,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "buyAndSellPublic",
    features: "Buy & Sell Public Shares",
    tier_1: false,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "receivePublicShares",
    features: "Receive Public Shares when Private Shares go Public",
    tier_1: false,
    tier_2: true,
    tier_3: true,
    tier_4: true,
  },
  {
    key: "investInPrimaryBFPrivate",
    features: "Invest in Primary BF Private Equity Market Deals",
    tier_1: false,
    tier_2: false,
    tier_3: "soon",
    tier_4: true,
  },
  {
    key: "buyAndSellOnSecondary",
    features: "Buy & Sell on Secondary BF Private Equity Market",
    tier_1: false,
    tier_2: false,
    tier_3: "soon",
    tier_4: true,
  },
  {
    key: "joinBFPrivateClientSolution",
    features: "Join BF Private Client Solution",
    tier_1: false,
    tier_2: false,
    tier_3: false,
    tier_4: true,
  },
  {
    key: "attendBFPrivateClient",
    features: "Attend BF Private Client WealthHub Investor Webinars",
    tier_1: false,
    tier_2: false,
    tier_3: false,
    tier_4: true,
  },
  {
    key: "setupBFPortfolioBuilder",
    features: isUkFinProm
      ? "Setup BF Portfolio Builder Wealth Structure"
      : "Setup BF Portfolio Builder Asset Protection Structure",
    tier_1: false,
    tier_2: false,
    tier_3: false,
    tier_4: true,
  },
  {
    key: "premiumServiceOffering",
    features: "Premium Service Offering for BF Private Clients",
    tier_1: false,
    tier_2: false,
    tier_3: false,
    tier_4: true,
  },
];

export const getColumns = (isUkFinProm?: boolean) =>
  [
    {
      title: "Features",
      dataIndex: "features",
      key: "features",
    },
    {
      title: <Tier1BadgeSVG />,
      key: "tier_1",
      render: (_, record) => {
        if (record.tier_1 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_1 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: <Tier2BadgeSVG />,
      key: "tier_2",
      render: (_, record) => {
        if (record.tier_2 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_2 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: <Tier3BadgeSVG />,
      key: "tier_3",
      render: (_, record) => {
        if (record.tier_3 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_3 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: isUkFinProm ? <Tier4BadgeUkSVG /> : <Tier4BadgeSVG />,
      key: "tier_4",
      render: (_, record) => {
        if (record.tier_4 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_4 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
  ] as ColumnsType<RowData>;

export const getMobileColumns = (isUkFinProm?: boolean) =>
  [
    {
      title: <Tier1ShortBadgeSVG />,
      key: "tier_1",
      render: (_, record) => {
        if (record.tier_1 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_1 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: <Tier2ShortBadgeSVG />,
      key: "tier_2",
      render: (_, record) => {
        if (record.tier_2 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_2 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: <Tier3ShortBadgeSVG />,
      key: "tier_3",
      render: (_, record) => {
        if (record.tier_3 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_3 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
    {
      title: isUkFinProm ? <Tier4ShortBadgeUkSVG /> : <Tier4ShortBadgeSVG />,
      key: "tier_4",
      render: (_, record) => {
        if (record.tier_4 === "soon") {
          return <span className={styles.soonBadge}>SOON</span>;
        }

        return record.tier_4 ? <TickOutlineSVG /> : <MinusSVG />;
      },
    },
  ] as ColumnsType<RowData>;
