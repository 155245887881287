import React from "react";

import cn from "classnames";

import { PROFILE_TIER } from "constants/account";
import { useUserData } from "hooks";
import { ProfileTier } from "types/account";
import { splitSnakeCase } from "utils/textFormat";

import {
  Tier1BadgeSVG,
  Tier1ShortBadgeSVG,
  Tier2BadgeSVG,
  Tier2ShortBadgeSVG,
  Tier3BadgeSVG,
  Tier3ShortBadgeSVG,
  Tier4BadgeSVG,
  Tier4ShortBadgeSVG,
  Tier4BadgeUkSVG,
  Tier4ShortBadgeUkSVG,
} from "./icons";

import styles from "./TierBadge.module.scss";

type Props = {
  tier: ProfileTier;
  short?: boolean;
};

export const TierBadge = ({ tier, short }: Props) => {
  const { data } = useUserData();

  switch (tier) {
    case PROFILE_TIER.tier_1: {
      return short ? <Tier1ShortBadgeSVG /> : <Tier1BadgeSVG data-cy='tier-badge__tier-1' />;
    }
    case PROFILE_TIER.tier_1_uk_restricted: {
      return short ? <Tier1ShortBadgeSVG /> : <Tier1BadgeSVG data-cy='tier-badge__tier-1' />;
    }
    case PROFILE_TIER.tier_2: {
      return short ? (
        <Tier2ShortBadgeSVG data-cy='tier-badge__tier-2' />
      ) : (
        <Tier2BadgeSVG data-cy='tier-badge__tier-2' />
      );
    }
    case PROFILE_TIER.tier_3: {
      return short ? (
        <Tier3ShortBadgeSVG data-cy='tier-badge__tier-3' />
      ) : (
        <Tier3BadgeSVG data-cy='tier-badge__tier-3' />
      );
    }
    case PROFILE_TIER.tier_4: {
      return short ? (
        data?.UKFinProm ? (
          <Tier4ShortBadgeUkSVG data-cy='tier-badge__tier-4' />
        ) : (
          <Tier4ShortBadgeSVG data-cy='tier-badge__tier-4' />
        )
      ) : data?.UKFinProm ? (
        <Tier4BadgeUkSVG data-cy='tier-badge__tier-4' />
      ) : (
        <Tier4BadgeSVG data-cy='tier-badge__tier-4' />
      );
    }

    default: {
      const tierLabel = splitSnakeCase(tier.replace(/_kyc$/, ""));
      return <span className={cn(styles.tierBadge, styles[tier])}>{tierLabel}</span>;
    }
  }
};
